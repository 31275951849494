import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

import {Heading, P, SectionHeader} from '../../Misc/Typogrpahy/Typography';
import { squareGridBackground } from '../../Layouts/LayoutWrap/GlobalStyles';

const StyledDrop = styled.input`
  outline: none;
  &:disabled {
    cursor: not-allowed;
  }
`;
const DropLabel = styled.label`
  outline: none;
  cursor: pointer;
  background-color: rgba(201, 197, 182, 0.1);
  border-bottom: 1px solid #BFBBAC;
  font-family: 'Helvetica',sans-serif;
  text-rendering: optimizeLegibility;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.7rem;
  padding: 2rem;
  text-align: center;
  color: #797566;
  display: flex;
  flex-direction: column;
  &:hover, &:focus {
    border-color: #000;
  }
  &:disabled {
    cursor: not-allowed;
  }
  ${P} {
    margin-bottom: 0;
    ${P} {
      margin-left: 1rem;
    }
  }
`;
const DropZoneWrapper = styled.div`
  ${squareGridBackground};
  margin-bottom: 2rem;
  min-height: 25rem;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  ${DropLabel} {
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const DropWrapper = styled.div`
  ${SectionHeader} {
    margin: 0 0 3rem;
    text-align: left;
  }
  ${Heading} {
    max-width: none;
    margin: 0;
    color: #979384;
  }
`;
const FileListItem = styled.li``;
const FileList = styled.ul``;

const FileDrop =
  ({ input, label, id, meta: { error }, disabled, name, title, required, }) => {
    const [dropAccepted, setDropAccepted] = useState(false);
    const [touched, setTouched] = useState(false);
    const onDrop = useCallback(acceptedFiles => {
      // const filesBinary = [];
      // acceptedFiles.forEach((file) => {
      //   const reader = new FileReader();
      //   reader.onabort = () => console.log('file reading was aborted');
      //   reader.onerror = () => console.log('file reading has failed');
      //   reader.onload = () => {
      //     filesBinary.push(reader.result);
      //   };
      //   reader.readAsArrayBuffer(file);
      // })
      input.onChange(acceptedFiles);
    }, [input]);
    const {
      acceptedFiles, getRootProps, getInputProps, isDragActive
    } = useDropzone({
      onDrop,
      disabled,
      multiple: true,
      onFileDialogCancel: () => setTouched(true),
      onDropAccepted: () => setDropAccepted(true),
      onDropRejected: () => setDropAccepted(false),
    });

    const files = acceptedFiles.map(
      ({ name, size}, index) =>
       (<FileListItem key={`${name}-${index}`}>
          <P small={true}>
            {name.substr(0, 20)}... - {(size * 0.000001).toFixed(2)} MB
          </P>
        </FileListItem>)
    );

    const dropZoneText =
      dropAccepted
        ? 'Files added: '
        : 'Drag \'n\' drop some files here, or click to select files';

    return (
      <DropWrapper>
        <SectionHeader>
          <Heading as={'h4'}>{title}{required && ' *'}</Heading>
          {required && touched && error && <P as={'span'} small={true} color={'red'}>{error}</P>}
        </SectionHeader>
        <DropZoneWrapper>
          <DropLabel {...getRootProps()}  htmlFor={id || ''}>
            <StyledDrop {...getInputProps()} name={name}/>
            {isDragActive
              ? <P small={true}>Drop the files here...</P>
              : <P small={true}>{dropZoneText}</P>
            }
            {dropAccepted && (
              <FileList>{files}</FileList>
            )}
          </DropLabel>
        </DropZoneWrapper>
      </DropWrapper>
    );
  }

FileDrop.defaultProps = {
  input: {},
  id: '',
  disabled: false,
  meta: {},
};

FileDrop.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default FileDrop;
