import React from 'react'
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import {
  containerMixin, responsiveQuery, squareGridBackground
} from '../components/Layouts/LayoutWrap/GlobalStyles';
import MainLayout from '../components/Layouts/MainLayout/MainLayout';
import Seo from '../components/Misc/Seo/Seo';
import ServiceForm, { InputsGroup } from '../components/Forms/ServiceForm/ServiceForm';
import { CheckboxGroupWrapper } from '../components/Forms/Fields/Checkbox';
import { TextareaWrapper } from '../components/Forms/Fields/TextArea';
import { DropWrapper } from '../components/Forms/Fields/FileDrop';
import { Heading, HR, P, SectionHeader } from '../components/Misc/Typogrpahy/Typography';
import BackgroundImage from '../components/Misc/Image/BackgroundImage';
import ContactForm from '../components/Forms/ContactForm/ContactForm';

const SectionContent = styled.div`
  ${containerMixin};
`;
const HeroImage = styled(BackgroundImage)`
  height: 30rem;
  ${responsiveQuery.mobileL`
    height: 30vh;
  `};
`;
const ServiceFormSection = styled.section`
  padding: 20rem 0 20rem;
  position: relative;
  > * {
   z-index: 3;
   position: relative;
  }
  &:after {
    ${squareGridBackground};
    content: '';
    height: 30rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    right: 0;
  }
  &:before {
    content: '';
    background: linear-gradient(0deg, rgba(191, 187, 172, 0.24) 0%, rgba(255, 255, 255, 0) 53.13%, #FFFFFF 100%);
    height: 30rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    right: 0;
  }
  form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: calc(100% - (2 * var(--grid-cut)));
    ${responsiveQuery.laptop`
      max-width: 100%;
    `};
    button {
      align-self: flex-end;
      + div {
        margin-top: 2rem;
        text-align: center;
      }
      ${responsiveQuery.mobileL`
        align-self: stretch;
      `}
    }
  }
  ${InputsGroup} {
    display: flex;
    ${responsiveQuery.mobileL`
      flex-direction: column;
    `};
    > div {
      flex: 0 0 32%;
      margin-bottom: 6rem;
      &:not(:first-of-type) {
        margin-left: 1.75%;
      }
    }
  }
  ${CheckboxGroupWrapper} {
    margin-bottom: 6rem;
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      ${responsiveQuery.mobileL`
        flex-direction: column;
      `};
      li {
        flex: 0 0 30%;
        display: flex;
        align-items: flex-start;
        label {
          align-items: flex-start;
          &:before {
            position: absolute;
            margin-top: 0.5rem;
          }
          > p {
            margin-left: 3rem;
          }
        }
      }
    }
  }
  ${DropWrapper} {
    margin-bottom: 6rem;
  }
  ${TextareaWrapper} {
    margin-bottom: 6rem;
    textarea {
      min-height: 19rem;
    }
  }
`;
const ContactFormSection = styled.section`
  margin-bottom: 20rem;
  ${SectionHeader} {
    max-width: calc(100% - calc(2 * var(--grid-cut)));
    margin: -10rem auto 8rem;
    padding-top: 5rem;
    z-index: 2;
    background-color: #fff;
    position: relative;
    ${responsiveQuery.laptop`
      padding: 5rem 2rem 0;
      max-width: 100%;
    `};
  }
`;



const ContactUs = () => {
  const data = useStaticQuery(graphql`
    query ContactPageQuery {
      site {
        siteMetadata {
          pages {
            contact {
              metaTitle
              title
              description
              heroImage
            }
          }
        }
      }
    }
  `);
  const {
    metaTitle,
    title,
    description,
    heroImage,
  } = data.site.siteMetadata.pages.contact;
  return (
    <MainLayout>
      <Seo title={metaTitle} />
      <HeroImage filename={heroImage} />
      <ServiceFormSection>
        <SectionContent>
          <SectionHeader>
            <Heading as='h2'>{title}</Heading>
            <HR />
            <P>{description}</P>
          </SectionHeader>
          <ServiceForm />
        </SectionContent>
      </ServiceFormSection>
      <ContactFormSection>
        <SectionContent>
          <SectionHeader>
            <Heading as='h2'>{title}</Heading>
            <HR />
            <P>{description}</P>
          </SectionHeader>
        </SectionContent>
        <SectionContent>
          <ContactForm />
        </SectionContent>
      </ContactFormSection>
    </MainLayout>
  );
};

export default ContactUs;
