import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'redux-form';

import { Heading, P, SectionHeader } from '../../Misc/Typogrpahy/Typography';

const CheckboxWrapper = styled.div`
  display: inline-flex;
  margin-bottom: 1.5rem;
`;
const CheckboxLabel = styled.label`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  &:before {
    margin-right: 1rem;
    content: '';
    display: inline-flex;
    width: 2rem;
    height: 2rem;
    border: 1px solid #BFBBAC;
    background: rgba(201, 197, 182, 0.1);
  }
`;
const StyledCheckBox = styled.input`
  display: none;
  &:checked {
    + ${CheckboxLabel} {
      &:before {
        box-shadow: inset 2px 2px 0 0 white, inset -2px -2px 0 0 white;
        background: rgba(201, 197, 182, 1);
      }
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
`;
export const CheckboxGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${SectionHeader} {
    margin: 0 0 3rem;
    text-align: left;
  }
  ${Heading} {
    max-width: none;
    margin: 0;
    color: #979384;
  }
`;
const CheckboxList = styled.ul`
  display: flex;
  flex-direction: column;
`;
const CheckboxListItem = styled.li`
  display: inline-flex;
`;

const Checkbox = ({ input, disabled, label, id, option, onError, isGroup }) => {
  return (
    <CheckboxWrapper>
      <StyledCheckBox
        {...input}
        type={'checkbox'}
        id={id || ''}
        disabled={disabled}
        checked={input.value.indexOf(option.label) !== -1}
        onChange={(event) => {
          const newValue = [...input.value];
          if (event.target.checked) newValue.push(option.label);
          else newValue.splice(newValue.indexOf(option.label), 1);
          onError(newValue)
          return input.onChange(newValue);
        }}
      />
      <CheckboxLabel
        htmlFor={id || ''}
      >
        <P small={true}>{label}</P>
      </CheckboxLabel>
    </CheckboxWrapper>
  );
}

Checkbox.defaultProps = {
  label: '',
  disabled: false,
  id: '',
  input: {},
  meta: {},
  isGroup: false,
};

Checkbox.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  isGroup: PropTypes.bool.isRequired,
};

export const CheckboxGroup = ({ options, disabled, groupTitle, required, name, headingAs }) => {
  const [hasError, setHasError] = useState(false);
  const onError = (data) => {
    if (!data.length) setHasError(true);
    else setHasError(false);
  };
  return (
    <CheckboxGroupWrapper>
      <SectionHeader>
        <Heading as={headingAs}>{groupTitle}{required && ' *'}</Heading>
        {required && hasError && <P as={'span'} small={true} color={'red'}>Select one option</P>}
      </SectionHeader>
      <CheckboxList>
        {options.length > 0 && options.map(({id, label}, index) => (
          <CheckboxListItem key={`${name}-${index}`}>
            <Field
              isGroup={true}
              id={id}
              label={label}
              name={name}
              value={id}
              disabled={disabled}
              option={{id, label}}
              onError={onError}
              component={Checkbox}
            />
          </CheckboxListItem>
        ))}
      </CheckboxList>
    </CheckboxGroupWrapper>
  );
}

CheckboxGroup.defaultProps = {
  options: [],
  disabled: false,
  groupTitle: '',
  required: false,
  name: '',
  headingAs: 'h4'
};

CheckboxGroup.propTypes = {
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  groupTitle: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  headingAs: PropTypes.string,
};
