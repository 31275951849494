import { validateEmail } from '../../../_services/utils';

const serviceFormValidator =
  ({ companyName, countrySelect, citySelect, objectType, objectSize, fileUpload, companyEmail }) => {
    const errors = {};

    if (!validateEmail(companyEmail)) errors.email = 'Incorrect email';
    if (!companyEmail) errors.email = 'Required';
    if (!companyName) errors.companyName = 'Required';
    if (!countrySelect) errors.countrySelect = 'Required';
    if (!citySelect) errors.citySelect = 'Required';
    if (!objectType) errors.objectType = 'Required';
    if (!objectSize || isNaN(objectSize) || objectSize <= 0) errors.objectSize = 'Required';
    if (!fileUpload) errors.fileUpload = 'Upload files';

    return errors;
  };

export default serviceFormValidator;
