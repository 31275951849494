import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import { P } from '../../Misc/Typogrpahy/Typography';

const StyledSelect = styled(ReactSelect)`
  border: none;
  width: 100%;
  background-color: rgba(201, 197, 182, 0.1);
  border-bottom: 1px solid #BFBBAC;
  padding: 0.1rem 1rem;
  outline: none;
  font-family: 'Helvetica', sans-serif;
  text-rendering: optimizeLegibility;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.7rem;
  max-height: 3.8rem;
  color: #797566;
  &:-webkit-autofill,
  &:-webkit-autofill:hover {
    -webkit-text-fill-color: #797566;
    -webkit-box-shadow: 0 100px 0 1000px #f5f3ef inset;
  }
  &:-webkit-autofill:hover {
    border-color: #000;
  }
  :hover {
    border-color: #000;
  }
  &:focus, &:-webkit-autofill:focus, &:focus-within {
    border-color: #000;
    -webkit-text-fill-color: #000;
    color: #000;
    .react-select {
      &__single-value {
        color: #797566 !important;
      }
    }
  }
  .react-select {
    &__control {
      border: none;
      background: none;
      min-height: auto;
      &:hover, &--is-focused {
        border: none;
        box-shadow: none;
        .react-select__single-value {
          color: #000;
        }
      }
    }
    &__indicator {
      padding: 0;
    }
    &__indicator-separator {
     display: none;
    }
    &__value-container {
      padding: 0;
      min-height: auto;
    }
    &__single-value {
      color: #797566;
    }
    &__menu {
      margin: 1px 0;
      box-shadow: 0 4px 11px hsla(0,0%,0%,0.1);
      border-radius: 0;
      left: 0;
      &-list {
        padding: 0;
      }
    }
    &__option {
      color: #797566 !important;
      &--is-focused {
        background: rgba(201,197,182,0.1);
        cursor: pointer;
        color: #000;
      }
      &--is-selected {
        background-color: #BFBBAC;
      }
      &:active {
        background-color: #797566;
      }
    }
  }
`;
const SelectLabel = styled.label`
  ${P} {
    margin-bottom: 0;
    ${P} {
      margin-left: 1rem;
    }
  }
`;
const SelectWrapper = styled.div`
  margin-bottom: 2rem;
`;

const SelectComponent =
  ({
     input, placeholder, label, type, id, meta: { touched, error }, name,
     additional, disabled, isClearable, options, callBack,
  }) =>
    (<SelectWrapper>
      <SelectLabel htmlFor={id || ''}>
        <StyledSelect
          {...input}
          {...additional}
          options={options}
          value={input.value}
          placeholder={placeholder || ''}
          id={id || ''}
          name={name || ''}
          type={type}
          isDisabled={disabled}
          isClearable={isClearable}
          className={'react-select'}
          classNamePrefix={'react-select'}
          onChange={(value) => {
            input.onChange(value);
            callBack && callBack(value);
          }}
          onBlur={() => {
            input.onBlur()
          }}
        />
        <P small={true}>{label}{touched && error && <P as={'span'} small={true} color={'red'}>{error}</P>}</P>
      </SelectLabel>
    </SelectWrapper>);

SelectComponent.defaultProps = {
  type: 'text',
  id: '',
  input: {},
  placeholder: '',
  value: '',
  focus: false,
  disabled: false,
  additional: {},
  isClearable: false,
  callBack: () => {},
};

SelectComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  input: PropTypes.object,
  type: PropTypes.string,
  id: PropTypes.string,
  meta: PropTypes.object.isRequired,
  focus: PropTypes.bool,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  additional: PropTypes.object,
  callBack: PropTypes.func,
};

export default SelectComponent;
