import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { graphql, useStaticQuery } from 'gatsby';
import { change, untouch } from 'redux-form';
import styled from 'styled-components';

import serviceFormValidator from './serviceFormValidator';
import { submitServiceForm } from '../../../store/actions/forms';
import { toPascalCase }  from '../../../_services/utils';

import Input from '../Fields/Input';
import Button from '../Fields/Button';
import TextArea from '../Fields/TextArea';
import Select from '../Fields/Select';
import FileDrop from '../Fields/FileDrop';
import { CheckboxGroup } from '../Fields/Checkbox';
import { P } from '../../Misc/Typogrpahy/Typography';

const FormStatus = styled.div``;

export const InputsGroup = styled.div``;

const ServiceForm = ({
  pristine, invalid, handleSubmit, submittingForm, onSubmit, submitError, formSuccess,
  successMessage, errorMessage, dispatch
}) => {
  const data = useStaticQuery(graphql`
    query ServiceFormQuery {
        site {
            siteMetadata {
                serviceForm {
                    allServiceTypes {
                        nodes {
                          data
                        }
                    }
                    allServiceSubTypes {
                        nodes {
                            data
                        }
                    }
                    constructionObjectTypes {
                        data
                    }
                    allEmiratesCity {
                        nodes {
                            data {
                                cities
                                name
                            }
                        }
                    }
                }
            }
        }
    }
  `);

  const resetFields = useCallback((formName, fieldsObj) => {
    Object.keys(fieldsObj).forEach(fieldKey => {
      dispatch(change(formName, fieldKey, fieldsObj[fieldKey]));
      dispatch(untouch(formName, fieldKey));
    });
  }, [dispatch]);

  const [selectedEmirate, setSelectedEmirate] = useState(-1);

  const { allServiceTypes, allServiceSubTypes, constructionObjectTypes, allEmiratesCity } = data.site.siteMetadata.serviceForm;

  const SERVICE_TYPES = allServiceTypes.nodes[0].data.map((item) => ({ id: toPascalCase(item), label: item }));
  const SERVICE_SUB_TYPES = allServiceSubTypes.nodes[0].data.map((item) => ({ id: toPascalCase(item), label: item }));
  const CONSTRUCTION_OBJECT_TYPES = constructionObjectTypes.data.map((item) => ({ value: toPascalCase(item), label: item }));

  const EMIRATES = allEmiratesCity.nodes[0].data.map(({ name }) => name).map((item) => ({ value: toPascalCase(item), label: item }));
  const EMIRATES_CITIES = allEmiratesCity.nodes[0].data.map(({ cities, name }) =>
    ({
      name,
      cities: cities.map((item) => ({value: toPascalCase(item), label: item}))
    })
  );

  const emiratesChange = (value) => setSelectedEmirate( EMIRATES_CITIES.findIndex(el => el.name === (value && value.label)));
  useEffect(() => {
    resetFields('ServiceForm', {
      citySelect: '',
    });
  }, [selectedEmirate, resetFields])

  return (
    <form
      onSubmit={handleSubmit((values) => {
        onSubmit(values)
      })}
      noValidate
    >
      <InputsGroup>
        <Field
          id={'companyName'}
          name={'companyName'}
          label={'Company name *'}
          type={'text'}
          disabled={submittingForm}
          component={Input}
        />
        <Field
          id={'emirateSelect'}
          name={'emirateSelect'}
          label={'Emirate *'}
          options={EMIRATES}
          disabled={submittingForm}
          component={Select}
          callBack={emiratesChange}
        />
        <Field
          id={'citySelect'}
          name={'citySelect'}
          label={'City *'}
          options={selectedEmirate > -1 ? EMIRATES_CITIES[selectedEmirate].cities : ''}
          disabled={submittingForm || selectedEmirate === -1}
          component={Select}
        />
      </InputsGroup>

      <InputsGroup>
        <Field
          id={'objectType'}
          name={'objectType'}
          label={'Type of construction object *'}
          options={CONSTRUCTION_OBJECT_TYPES}
          disabled={submittingForm}
          component={Select}
        />
        <Field
          id={'objectSize'}
          name={'objectSize'}
          label={'How many squares? *'}
          type={'number'}
          additional={{min: 0}}
          disabled={submittingForm}
          component={Input}
        />
        <Field
          id={`companyEmail`}
          name={'companyEmail'}
          label={'Email *'}
          type={'text'}
          disabled={submittingForm}
          component={Input}
        />
      </InputsGroup>

      <CheckboxGroup
        groupTitle={'I need (Service type)'}
        name={'serviceType'}
        options={SERVICE_TYPES}
        disabled={submittingForm}
        required={true}
      />

      <CheckboxGroup
        groupTitle={'Services Subtype'}
        name={'serviceSubtype'}
        options={SERVICE_SUB_TYPES}
        disabled={submittingForm}
        required={true}
      />

      <Field
        title={'Send us required files'}
        id={`fileUpload`}
        name={'fileUpload'}
        disabled={submittingForm}
        required={true}
        component={FileDrop}
      />

      <Field
        id={`additional`}
        name={'additional'}
        label={'Type message here'}
        title={'Additional questions (description)'}
        disabled={submittingForm}
        component={TextArea}
      />
      <Button aria-label={'Submit form'} disabled={pristine || invalid || submittingForm} submitting={submittingForm}/>
      {(submitError !== '' || formSuccess) && (
        <FormStatus>
          {submitError !== '' && <P color={'red'}>{errorMessage}</P>}
          {formSuccess && <P>{successMessage}</P>}
        </FormStatus>
      )}
    </form>
  );
}

ServiceForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submittingForm: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitError: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
};

ServiceForm.defaultProps = {
  successMessage: 'Form successfully submitted!',
  errorMessage: 'There was an error while submitting the form!',
};

const mapStateToProps = ({ forms }) => ({
  submittingForm: forms.submittingServiceForm,
  submitError: forms.submittingServiceFormError,
  formSuccess: forms.submittingServiceFormSuccess,
});

const mapDispatchToProps = { onSubmit: submitServiceForm }

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    validate: serviceFormValidator,
    form: 'ServiceForm',
  })
)(ServiceForm);
